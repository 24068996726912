import { useEffect, useState } from "react"
import { SetMap } from "./Components/setMap"
import { getReport } from "./api/getReportApi"

import Header from './Components/Header'
import getRecorridoPetition from "./api/getRecorridoPetitionApi"
import RecorridoList from "./Components/RoutesListComponent/RecorridoList"
import { ComponentViewReport } from "./Components/ComponentViewReport/ComponentViewReport"

import stylesViewReport from "./Components/ComponentViewReport/css/card_recorrido.module.css"

import { TableConfig } from "./Components/TableConfig"
import { MapComponentCreateSucursal } from "./Components/MapComponentCreateSucursal"
import { createQRSucursalApi } from "./api/createQRSucursalApi"
export default function Main() {

    const [addQRSucursal, setaddQRSucursal] = useState(0)

    const setReport = (Report)=>{
        var image = document.querySelector("#reporte_img")
        image.src = '/img/loading.gif'

        getReport(window.localStorage.getItem("credentials"), {
            "tableName": CurrentSucursal.sucursalName,
            "index": Report,
            "idRecorrido": CurrentRoute[0]
        }).then(r => r.json()).then(r => {

            if (r.image !== "0") {
                image.src = `data:image/png;base64,${r.image}`
            }else{
                image.src = '/img/logo_gesnu_min.png'
            }
            document.querySelector("#texto").innerHTML = `${r.description}`
            document.querySelector("#fecha").innerHTML = `${r.date}`
            document.querySelector("#report_component").classList.remove(stylesViewReport.hide)



        }
        )
    }

    const [mainData, setMainData] = useState(0)
    const [CurrentSucursal, setCurrentSucursal] = useState({})
    const [CurrentRoute, setCurrentRoute] = useState([])
    const [configs, setConfigs] = useState(0)
    const [userList, setUserList] = useState([])
    var map = ""
    var QRcircle = []
    var circleCoords = ""
    useEffect(() => {

        getRecorridoPetition().then(r => r.json()).then(r => {
            setCurrentSucursal({
                "routes": r.RouterList,
                "sucursalName": r.SucursalQRList.length > 0 ? r.currentRoute.length > 0
                    ? r.currentRoute[0][3]
                    : r.SucursalQRList[0][1]
                    : "Sin Sucursales",
                "SupervisorList": r.SupervisorList,
                "SucursalQRList": r.SucursalQRList,
                "GeoCerca": r.CurrentGeocerca
            })
            setMainData(r)

        })

    }, [])


    useEffect(() => {
        console.log(CurrentSucursal);
        if (CurrentSucursal.routes !== undefined) {
            let section = mainData.SucursalList.map(r => {
                return r[0] === mainData.CurrentGeocerca[0][0]
            }) ? "1" :
                mainData.SucursalQRList.map(r => {
                    return r[0] === mainData.CurrentGeocerca[0][0]

                }) ? "2" : ""
                console.log(section);
                for( var i = 0; CurrentSucursal.routes.length > i; i++){
                    if(CurrentSucursal.routes[i][4] != '[]'){
                        setCurrentRoute(CurrentSucursal.routes[i])
                        break   
                    }
                    else{
                    }
                }
                if (CurrentSucursal.routes.length == 0){
                    setCurrentRoute([])

                }
            setConfigs({
                "userList": CurrentSucursal.SupervisorList,
                "sucursalName": CurrentSucursal.sucursalName,
                "section": CurrentSucursal.section !== undefined ? CurrentSucursal.section : section

            })
        }

        setUserList(CurrentSucursal.SupervisorList)

    }, [CurrentSucursal, mainData.CurrentGeocerca, mainData.SucursalList, mainData.SucursalQRList])

    return <>
        <link rel="stylesheet" href="/css/main.css" />
        {
            mainData !== 0 ? <>

                {/* Header Component */}
                <Header setaddQRSucursal={setaddQRSucursal}
                    title={CurrentSucursal.sucursalName}
                    setCurrentSucursal={setCurrentSucursal}
                    SucursalList={mainData.SucursalList}
                    SucursalQRList={mainData.SucursalQRList} />

                {/* Map Component  */}
                <SetMap Recorrido={CurrentRoute}
                    setReporte={setReport}
                    CurrentGeocerca={CurrentSucursal.GeoCerca}
                    setaddQRSucursal={setaddQRSucursal} />

                {/* RouteList Component */}
                <RecorridoList
                    Routes={{
                        "list": CurrentSucursal.routes,
                        "mode": QRcircle.length > 1 ? "QR" : "normal"
                    }}
                    setCurrentRoute={setCurrentRoute}
                    sucursal={CurrentSucursal.sucursalName} /></> : ""}

        {/* Report Viewer Component */}
        <ComponentViewReport 
        // reporte={Report}
            setReporte={setReport}
            tableName={CurrentSucursal.sucursalName}
            title={CurrentSucursal.sucursalName}
            idRecorrido={CurrentRoute} />

        {/* Component Configs */}
        {configs !== 0 ? <TableConfig userList={userList}
            setUserList={setUserList}
            tablename={configs.sucursalName}
            Section={configs.section} /> : ""}

        {/* Component Create Sucursal*/}
        {addQRSucursal === 1 ? <MapComponentCreateSucursal map={map}
            circleCoords={circleCoords}
            QRcircle={QRcircle}
            createQRSucursalApi={createQRSucursalApi}
            setaddQRSucursal={setaddQRSucursal} /> : ""}

    </>
}


