import { useState } from "react";
import { ip } from "../../GlobalData"

export function UserSelector({setCardUserSelector, CardUserSelector,deluser,section}) {
    console.log(section);
    
    const [userType,setUserType] = useState(2)
    const [CardAddUser,setCardAddUser] = useState("")
    const [user, setUser] = useState("");
    const [passwr, setPasswr] = useState("");
    const [userMode,setUserMode] = useState("Supervisor")
    function checked(e) {if(e.target.checked == true){ setUserType(3) }else setUserType(2)}
    function onChangeTagInput(e,set) { set(e.target.value.replace(/[^a-zA-Z \d]/ig, "")) }
    return <>        
    <div className="card-user-selector">
        <div className="icon_close"><img src="./img/icon_close.png"  onClick={()=>setCardUserSelector("")}/></div>
        <div className="user-selector">
            <select id="user-selector">
                {
                    CardUserSelector.map(r=>{
                        if (r[3] != userType) return ""
                        return <>
                        
                        <option>  {r[1]}</option>

                        </>

                    })
                }
            </select>
            <i onClick={()=>{

                setCardAddUser(document.querySelector(".table_config header .title").innerHTML)



            }}>+</i>
            <i className="del" onClick={()=>{
                deluser()
                setCardUserSelector(false)
            }}>
            <img src="./img/icon_del.png" alt="" /> 
            </i>

        </div>
        <div className="mode">
            <div className="text">Modo Auditor</div>
            <input type="checkbox" name="" id="userMode" onChange={(e)=>{checked(e);userMode=="Auditor"?setUserMode("Supervisor"):setUserMode("Auditor")}} />
        </div>
        <input type="button" value="Agregar" onClick={()=>addUserToSucursal(section)}/>
    </div>

    {
        CardAddUser != ""?<>
        <form className='card_add_user' name='form_add_User'>
        <img src="./img/icon_close.png" onClick={()=>setCardAddUser("")} className='exit' />
        <div className="title">Agregar {userMode}</div>
        <input className="user" type="text" name="user" placeholder='Nombre'  value={user} onChange={(e) => onChangeTagInput(e,setUser)} autoComplete="off"/>
        <input className="passw" type="password"  name="passwr" placeholder='Contraseña'  value={passwr} onChange={(e) => onChangeTagInput(e,setPasswr)} autoComplete="off"/>

        <input type="button" className="submit" value="Entrar" onClick={()=>{
            
            
            if(
                document.querySelector(".user").value != "" &&
                document.querySelector(".passw").value != "" 
            ){
                addUser(userType)
                setCardAddUser("")
                setCardUserSelector("")
            }
            
           }}/>

    </form></>:""
    }
    </>
}

export  function userSelector(setCardUserSelector) {
        
        
    var petition = [window.localStorage.getItem("credentials"),
    ]


    fetch(`${ip}/protected/GetUsersfromCompany`,{
    method:'POST',
    headers:{
    'Content-Type':"application/json"

    },
    body:JSON.stringify(petition)

    }).then(r=>r.json()).then(r=>{

        setCardUserSelector(r)



    })

}
const addUser = (userType)=>{
    var petition = [window.localStorage.getItem("credentials"),
                        document.querySelector(".card_add_user input[name=\"user\"]").value,
                        document.querySelector(".card_add_user input[name=\"passwr\"]").value,
                        userType
                        ]
            
        var result = ""

        var resp = fetch(`${ip}/web/addUser`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)
    
        }).then(r=>r.json()).then(r=>{
        if(r[0] == "success") result = 200
        if(r[0] == "201") alert("Este usuario ya esta Registrado.")


    })
    return result
}
const addUserToSucursal = (section)=>{
    const selector = document.querySelector("#user-selector").value
    var userMode = document.querySelector("#userMode").checked
    var typeUser = "2"
    if(userMode){
        
        // if(section == "1") typeUser = "4"
            typeUser = "4"
        
    }
    if (selector == "") return false
    var petition = [window.localStorage.getItem("credentials"),
                    document.querySelector(".table_config .title").innerHTML,
                    selector,
                    typeUser]
            
       fetch(`${ip}/web/addUsertoReportesQr`,{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
                
            },
            body:JSON.stringify(petition)
    
        }).then(r=>{
        if(r.status === 501) return alert("Este usuario ya esta En otra sucursal")
            
        return r.json()
    
        }).then(r=>{
        if(r[0] === "success") window.location.reload()

    })



}